@import '../../assets/scss/variables.scss';

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.header {
  height: 70px;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
}

.header img {
  width: 200px;
  height: 20px;
  margin-top: 8px;

  @media (max-width: 991px) {
    width: 75px;
    height: 10px;
    margin-left: 20px;
  }  
}
.top-menu {
  background-color: #f5f7f9;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;

  a {
    color: #000;
    padding: 12px 40px;
    font-size: 13px;

    &.active  {
      background-color: $brandColor;
      color: #fff;
      font-weight: 600;
    }
  }
}

.user-dropdown  {
  width: auto;
  @media (max-width: 991px) {
    
  }
}

.user-profile {
  width: 40px;
  height: 40px;
  background-color: $secondaryColor;
  border-radius: 50em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;

  @media (max-width: 991px) {
    font-size: 11px;
    width: 20px;
    height: 20px;
  }
}