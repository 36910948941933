@import '../assets/scss/variables.scss';

.password-wrapper  {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #F5F6F8; 

  hr  {
    border-top-width: 2px;
    border-top-color: $primaryColor;
    width: 150px;
  }

  .card {
    width: 450px;
    max-width: 100%;
    border-radius: 20px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.16);
  }
}