@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;500;600&display=swap");
@import "./variables.scss";
body {
  font-family: "Sarabun", sans-serif;
  font-size: 14px;

  @media (max-width: 991px) {
    font-size: 11px;
  }
}

.wrapper {
  min-height: 100vh;
  padding-bottom: 70px;
  padding-top: 70px;
}

hr {
  border-top-color: $borderColor;
}

.line-highlight {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -1px;
    height: 3px;
    width: 120px;
    background-color: $brandColor;
  }
}

.container {
  @media (min-width: 1320px) {
    max-width: 1380px;
  }

  @media (min-width: 1440px) {
    &.container-lg {
      max-width: 1440px;
    }
  }

  @media (max-width: 991px) {
    max-width: none !important;
  }
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.manage-content {
  @media (max-width: 767px) {
    padding: 16px;
  }
}

.img-banner {
  @media (min-width: 1320px) {
    width: 630px;
    height: 400px;
  }

  @media (max-width: 991px) {
    width: 120px;
    height: 120px;
  }
}

.img-article {
  @media (min-width: 1320px) {
    width: 300px;
    height: 240px;
  }

  @media (max-width: 991px) {
    width: 160px;
    height: 120px;
  }
}

.img-writer {
  @media (max-width: 991px) {
    width: 120px;
    height: 120px;
  }
}

.ant-tag {
  @media (min-width: 1320px) {
    white-space: nowrap;
  }

  @media (max-width: 991px) {
    white-space: normal;
    text-align: center;
  }
}

// .container {
//   @media(min-width: 1330px) {
//     max-width: 1300px;
//   }
// }

.content-container {
  display: flex;
  height: calc(100vh - 60px);
}

.inner-sidebar {
  width: 300px;
  margin-right: 30px;
  background-color: #f5f7f9;

  .sidebat-nav-item {
    border-radius: 8px;
    padding: 0.75rem 15px;

    &.active {
      background-color: #fff;

      .step-circle {
        background-color: $brandColor;
      }
    }
  }
}

.ant-input {
  border-color: $borderColor;
  line-height: 3.5715;
  padding: 8px 16px;
  height: 42px;

  &:hover,
  &:focus {
    border-color: $borderHoverColor;
    outline: none;
    box-shadow: none;
  }

  @media (min-width: 1320px) {
    font-size: 14px;
  }

  @media (max-width: 991px) {
    font-size: 11px !important;
  }
}

.ant-select {
  @media (max-width: 991px) {
    font-size: 11px;
  }
}

.ant-radio-wrapper {
  @media (max-width: 991px) {
    font-size: 11px;
  }
}

.ant-card {
  @media (max-width: 991px) {
    font-size: 11px !important;
  }
}

.ant-tag {
  @media (max-width: 991px) {
    font-size: 10px !important;
    line-height: 12px !important;
  }
}

.ant-menu {
  @media (max-width: 991px) {
    font-size: 11px !important;
  }
}

.h5,
h5 {
  @media (max-width: 991px) {
    font-size: 0.65rem !important;
  }
}

.ant-picker-input > input {
  @media (max-width: 991px) {
    font-size: 11px !important;
  }
}

.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 30px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $borderColor;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $borderHoverColor;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: $borderHoverColor;
  box-shadow: none;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 42px;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 36px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 36px;
}

.ant-picker {
  border-color: $borderColor;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: $borderHoverColor;
  box-shadow: none;
}

.ant-picker-range .ant-picker-active-bar {
  background-color: $brandColor;
}

.ant-picker-input > input {
  height: 28px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: $brandColor;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: $brandColor;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-color: $borderColor;

  &:hover {
    border-color: $borderHoverColor;
  }
}

.ant-pagination-item-link svg {
  display: block;
}

.ant-pagination-item {
  border-color: $borderColor;
}

.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
  border-color: $borderHoverColor;
  background-color: #f5f7f9;
}

.ant-pagination-item-active {
  border-color: $brandColor;
}

.ant-btn-circle {
  .anticon {
    display: block;
  }
}

.ant-btn {
  font-weight: 600;

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  @media (max-width: 991px) {
    font-size: 11px !important;
    height: 24px !important;
    line-height: 1;
  }
}

.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 12px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $brandColor;
}

.ant-radio-inner::after {
  background-color: $brandColor;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  vertical-align: 1px;
}

.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-top: 0;
  padding-bottom: 5px;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #f5f7f9;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border-color: $borderColor;
}

.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom-color: $borderColor;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right-color: $borderColor;
}

.btn {
  font-weight: 600;

  &:not(.btn-sm) {
    font-size: 14px;
    padding: 10px 30px;
  }

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

.text-danger {
  font-size: 13px;
}

.btn-sm {
  font-size: 13px;
}

.btn-primary {
  background-color: $brandColor;
  border-color: $brandColor;

  &:hover,
  &:focus,
  &:active {
    background-color: lighten($brandColor, 5%) !important;
    border-color: lighten($brandColor, 5%) !important;
  }
}

.btn-secondary {
  background-color: $secondaryColor;
  border-color: $secondaryColor;

  &:hover,
  &:focus,
  &:active {
    background-color: lighten($secondaryColor, 5%) !important;
    border-color: lighten($secondaryColor, 5%) !important;
  }
}

.table_wrapper {
  @media (max-width: 991px) {
    display: block;
    white-space: nowrap;
  }
  overflow-x: auto;
}

.table thead th {
  border-bottom-width: 1px;
  background-color: #f5f7f9;
  border-color: $borderColor;
  font-weight: 600;
}

.table td,
.table th {
  border-color: $borderColor;
  padding: 0.5rem 0.75rem;
}

.card {
  border-color: $borderColor;
}

.header-bar {
  font-weight: 600;
  background-color: #f5f7f9;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 14px;
  border: 1px solid $borderColor;

  @media (max-width: 991px) {
    font-size: 11px !important;
  }
}

.header-bar-gold {
  font-weight: 600;
  background-color: #fff9d8;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 14px;
  border: 1px solid #ffed89;

  @media (max-width: 991px) {
    font-size: 11px !important;
  }
}

.header-bar-green {
  font-weight: 600;
  background-color: #e6f7eb;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 14px;
  border: 1px solid #b4e8c4;

  @media (max-width: 991px) {
    font-size: 11px !important;
  }
}


.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50em;
}

.step-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50em;
  width: 30px;
  height: 30px;
  background-color: $secondaryColor;
  color: #fff;
  font-weight: 600;
  font-size: 16px;

  &.active {
    background-color: $brandColor;
  }
}

.l-s-2 {
  letter-spacing: 2px;
}

.border {
  border-color: $borderColor !important;
}

.text-main {
  color: $brandColor !important;
}

.text-grey {
  color: #aaa !important;
}

.text-success {
  color: #7ec074 !important;
}

.bg-main {
  background-color: $brandColor;
}

.bg-grey {
  background-color: #f5f7f9;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-16 {
  font-size: 16px !important;
}

.image-placeholder {
  position: relative;
  background-color: #f2f2f2;
}

::-webkit-file-upload-button {
  cursor: pointer;
}

.input-file-hidden {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.book-preview-area {
  width: 200px;
  min-width: 200px;
}

.book-preview {
  width: 200px;
  height: 283px;
  overflow: hidden;
}

.pagination {
  .page-item {
    border-radius: 6px;

    &.active {
      .page-link {
        background-color: $primaryColor;
        border-color: $primaryColor;
      }
    }

    &:not(.active) {
      .page-link,
      .page-link:hover {
        color: #868e96;
      }
    }

    .page-link {
      border-radius: 6px;
    }

    + .page-item {
      margin-left: 5px;
      border-radius: 6px;
    }
  }
}

// .card-body {
//   background: #f5f7fa;
// }
.card-body {
  background: #f5f7fa;
  padding: 24px;

  @media (max-width: 767px) {
    padding: 16px;
  }

  &.p-lg {
    @media (min-width: 992px) {
      padding: 40px;
    }
  }
}

.spinner {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.ck-content {
  font-family: "Sarabun", serif;
  font-size: 18px;
  color: #191919;
  letter-spacing: 0.5px;

  p {
    margin-bottom: 15px;
    line-height: 200%;
  }

  hr {
    height: 2px;
  }
}

.content-wrapper {
  @media (min-width: 1320px) {
    max-width: 950px;
  }

  @media (max-width: 800px) {
    max-width: 280px;
  }

  margin-left: auto;
  margin-right: auto;

  figure {
    margin-bottom: 0;
  }

  .ql-editor {
    font-size: 18px;
    color: #191919;

    img {
      max-width: 100%;
    }

    p {
      line-height: 2;
    }
  }

  .ql-container {
    height: 500px;
    resize: vertical;
  }

  .ql-container,
  .preview-container {
    font-family: "Sarabun", serif;
    font-size: 18px;
    color: #191919;
    padding: 0 12px;
  }

  .ql-color-picker {
    position: relative;
    top: -4px;
  }

  .ql-color-picker {
    position: relative;
    top: -4px;
  }
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: $primaryColor;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: $primaryColor;
}

html .ql-toolbar button:hover .ql-stroke,
html .ql-toolbar button:active .ql-stroke,
html .ql-toolbar button:focus .ql-stroke {
  stroke: $primaryColor !important;
}

html .ql-toolbar button:hover .ql-fill,
html .ql-toolbar button:hover .ql-stroke.ql-fill,
html .ql-toolbar button:active .ql-fill,
html .ql-toolbar button:active .ql-stroke.ql-fill,
html .ql-toolbar button:focus .ql-fill,
html .ql-toolbar button:focus .ql-stroke.ql-fill {
  fill: $primaryColor !important;
}

html .ql-toolbar .ql-picker .ql-picker-options {
  padding: 6px 10px;
}

html .ql-toolbar .ql-picker .ql-picker-item:hover {
  color: #000 !important;
}

.ql-picker-label {
  border: 0;

  &:focus {
    outline: none;
  }
}

.chapter-editor-wrapper {
  position: relative;

  .bem {
    margin: 5px;
  }
  .ql-emojiblot .ap {
    line-height: initial;
  }

  &.editor-block::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.ql-color,
.ql-background {
  .ql-picker-options [data-value="color-picker"]:before {
    content: "เลือกสีเพิ่มเติม";
    font-size: 12px;
  }
}

.ql-color,
.ql-background {
  .ql-picker-options [data-value="color-picker"] {
    width: 100% !important;
    background-color: #f5f5f5 !important;
    height: 30px !important;
    text-align: center;
    color: #000;
    border-radius: 6px;
    padding: 3px 5px;
    margin-top: 10px;

    &:focus {
      outline: none;
    }

    &:hover {
      border-color: transparent !important;
      color: #000 !important;
      background-color: #eee !important;
    }
  }
}

.ql-snow .ql-color-picker {
  &.ql-color,
  &.ql-background {
    .ql-picker-item {
      &:focus {
        outline: none;
      }

      &[data-value="#FFFFFF"] {
        border-color: #ddd;
      }
    }
  }

  .ql-picker-item {
    width: 22px;
    height: 22px;
    border-radius: 3px;
  }
}

.ql-divider {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 2px;
    width: 10px;
    background-color: #818ea3;
  }
}

.ql-content-divider {
  border-top: 1px solid #ddd;
  margin: 15px 0;
}

.modal-content-preview {
  max-width: 1000px;
}

.modal-color-picker {
  max-width: 250px;
}

.sketch-picker > div:last-child {
  > div {
    width: 20px !important;
    height: 20px !important;

    &:first-child > span > div {
      border: 1px solid #ddd;
    }

    > span > div {
      box-shadow: none !important;

      &[title="#FFFFFF"] {
        border: 1px solid #ddd;
      }
    }
  }
}

.pcr-app .pcr-interaction .pcr-type.active {
  background: $primaryColor;
}

.ck-content {
  font-family: "Sarabun", serif;
  font-size: 16px;
  color: #191919;
  letter-spacing: 0.5px;

  p {
    margin-bottom: 15px;
    line-height: 200%;
  }

  hr {
    height: 2px;
  }
}

.ck.ck-editor__editable_inline {
  padding: 0 1px;
  min-height: 200px;
}

.align-title {
  @media (min-width: 1320px) {
    width: 130px;
  }
}

.align-title-small {
  @media (min-width: 1320px) {
    width: 110px;
  }
}

.align-title-xs {
  @media (min-width: 1320px) {
    width: 80px;
  }
}

.ck-emoji__actions {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
}

.ant-modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

