@import '../assets/scss/variables.scss';

.login-wrapper  {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #e9a2a0; 
  background: -webkit-linear-gradient(to right, $brandColor, $primaryColor); 
  background: linear-gradient(to right, $brandColor, $primaryColor); 

  hr  {
    border-top-width: 2px;
    border-top-color: $primaryColor;
    width: 150px;
  }

  .card {
    @media(min-width: 1320px) {
      width: 450px;
      max-width: 100%;
    }

  
    @media (max-width: 991px) {
      width: 300px;
    }
    // width: 450px;
    // max-width: 100%;
    border-radius: 20px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.16);
  }
}